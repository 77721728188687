import React, { useState, useEffect } from "react";
import '../../../styles/Screens/SubscriptionsContent/SubscriptionsContent.css'
import SubscriptionPlan from "../../../components/SubscriptionPlan/SubscriptionPlan";
import Separator from "../../../components/Separator";
import { createNewSubscriptionsIntent, fetchPlanFeatures, updateSubscription } from "../../../services/offer/OfferService";
import { getUserSubscriptions, cancelUserSubscriptino } from "../../../services/subscription/subscriptionService";
import ModalComponent from "../../../components/Modals/ModalComponent";
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useNavigate } from "react-router-dom";
import { sendEventToGTM } from "../../../analytics/AnalyticsManager";

const SubscriptionsContent = () => {
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
    const [currentPeriodEnd, setCurrentEndPeriod] = useState("");
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCancelationModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getCurretnSubscription = async () => {
            try {
                const plansData = await fetchPlanFeatures();

                setPlans(plansData || []);
                const data = await getUserSubscriptions();

                setSelectedPlan(data);
                const filteredPlan = plansData.find(plan => plan.product_type === `subscription_${data.data.stripe_subscription_id}`);

                setSelectedPlanDetails(filteredPlan || {});
                const dateObject = new Date(data.data.current_period_end);
                const formattedDate = format(dateObject, 'dd/MM/yyyy', { locale: pl });

                setCurrentEndPeriod(formattedDate);
                setLoading(false);
            } catch (error) {
                if (error.status === 404) {
                    sendEventToGTM({
                        eventName: 'navigation',
                        action: 'Go to',
                        label: '/paywall',
                        value: '1',
                    });
                    navigate('/paywall')
                }
                setLoading(false);
            }
        }

        getCurretnSubscription();
    }, [])

    const handlePlanChange = async (plan) => {
        try {
            const response = await updateSubscription(plan);
        } catch (error) {
            console.log('Error')
        }
    };

    const cancelSubscription = (e) => {
        e.preventDefault();
        setLoading(true);

        const cancelSubscription = async () => {
            try {
                const data = await cancelUserSubscriptino();
                setLoading(false);
                isCancelModalVisible(true)
            } catch (error) {
                setLoading(false);
            }

        }
        cancelSubscription();
    };

    const isCancelModalVisible = (visible) => {
        setIsModalOpen(visible);
    }

    const closeCancelationModal = () => {
        isCancelModalVisible(false)
        window.location.reload();
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="subscriptions-content">
            <ModalComponent isOpen={isCancelationModalOpen} onClose={closeCancelationModal} title={"Udało się"} description={"Subskrypcja została anulowana"} buttonText={"Ok"} />
            <div className="current-subscription">
                <h3 className="text-lg semibold blue-text mb-3">Subskrypcje</h3>
                {selectedPlan?.data?.revenuecat_subscription_id === null && (
                    <div className="subscription-header">
                        <div className="subscription-details">
                            <div className="subscription-detail">
                                <p className="text-s medium">Twoja obecna subskrypcja:</p>
                                <p className="text-s semibold blue-text">{selectedPlanDetails.name}</p>
                            </div>
                            <div className="subscription-detail">
                                <p className="text-s medium">Następna płatność:</p>
                                <p className="text-s semibold blue-text">{currentPeriodEnd}</p>
                            </div>
                        </div>
                        <button className="cancel-subscription" onClick={(e) => cancelSubscription(e)}>Anuluj subskrypcję</button>
                    </div>
                )}

                {selectedPlan?.data?.revenuecat_subscription_id === null &&
                    <SubscriptionPlan
                        title={selectedPlanDetails.name}
                        price={selectedPlanDetails.new_price}
                        // duration="Całkowity koszt 287,99 zł / kwartał"
                        details={['Dostęp do platformy', '3 różne ścieżki treningowe', '300h treningu']}
                        isSelected={true}
                        onClick={() => handlePlanChange('monthly')}
                    />
                }

                {selectedPlan?.data.revenuecat_subscription_id !== null && (
                    <div>
                        <h3>Aby zarządzać subskrypcją przejdź do aplikacji mobilnej</h3>
                    </div>
                )}
            </div>

            <div>
                <Separator />
            </div>

            {selectedPlan?.data?.revenuecat_subscription_id === null &&
                <div className="change-subscription-content">
                    <h4 className="text-lg semibold blue-text">Zmień plan</h4>
                    <p className="text-s medium mb-4">Wybierz jedną z dostępnych subskrypcji, aby zmienić plan</p>

                    {plans
                        .filter(plan => plan.product_type !== "subscription_" + selectedPlan.data.stripe_subscription_id)
                        .map(plan => (
                            <SubscriptionPlan
                                key={plan.id}
                                title={plan.name}
                                price={plan.new_price}
                                duration={plan.subtitle}
                                // details={plan.details}
                                details={['Dostęp do platformy', '3 różne ścieżki treningowe']}
                                isSelected={selectedPlan === plan.id}
                                onClick={() => handlePlanChange(plan)}
                            />
                        ))}
                </div>
            }
        </div>
    );
}

export default SubscriptionsContent
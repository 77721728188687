import { collection, getDocs } from "firebase/firestore";
import { db } from "../authorization/firebase.config";
import { getRequest, postRequest } from "../HttpService";
import { sendEventToGTM } from "../../analytics/AnalyticsManager";

export const fetchPlanFeatures = async () => {
  try {
    const response = await getRequest("/api/product")
    console.log(response);
    return response.data.filter(item => item.product_type != "one_purchase")
  } catch (error) {
    console.error("Error fetching offer: ", error);
    return [];
  }
};

export const createNewSubscriptionsIntent = async (id) => {
  try {
    const body = { product_id: id }

    const response = await postRequest('api/subscription/create', body)
    sendEventToGTM({
      eventName: 'subscription_init',
      action: 'subscription_init',
      label: `${String(id)}`,
      value: '1',
  });
    window.open(response.payment_url, "_self")
    return response
  } catch (error) {
    return error
  }
}
// api/subscriptions/update

export const updateSubscription = async (product) => {
  try {
  //   let body = {
  //     baselinker_item_id: "33115567",
  //     currency: "PLN",
  //     description: "{\n    \"includedFeature\": [\"Wszystko co w planie miesięcznym\"],\n    \"notIncludedFeatures\": [],\n    \"giftDescription\": \"Darmowa długa guma,duży zestaw 5 gum oporowych Shape Factor\",\n    \"giftImage\": \"https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/oimages%2Fsubscriptions%2Fgift_yearly.png?alt=media&token=d021cea9-d36e-4c51-8ade-2af0e58869b1\"\n}",
  //     id: 6,
  //     image: "https://firebasestorage.googleapis.com/v0/b/shape-factor.appspot.com/o/images%2Fc9o05_3.png?alt=media&token=37207921-7b89-464e-9467-e45a52249823",
  //     name: "Subskrypcja Roczna",
  //     new_price: 589.99,
  //     old_price: 719.0,
  //     product_type: "subscription_year",
  //     stripe_price_id: "price_1NtBxjHYuUaOKswQ1vEQEOAt"
  // }
    // console.log(body);
    const response = await postRequest('api/subscription/update', product)
    const response2 = await postRequest('api/subscription/check')
    // console.log(response2);
    return response;
  } catch (error) {
    return error
  }
}
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import './styles/global.css'
import LoginScreen from './routes/public/LoginScreen';
import RegisterScreen from './routes/public/RegisterScreen';
import { PrivateRoute, SecuredRoute } from "./routes/private/PrivateSecuredRoute";
import PaywallScreen from "./routes/private/PaywallScreen";
import { AuthProvider } from "./services/authorization/authContext";
import DashboardScreen from "./routes/private/DashboardScreen";
import TrainingModuleScreen from "./routes/private/TrainingModuleScreen";
import WorkoutScreen from "./routes/private/WorkoutScreen";
import ProfileScreen from "./routes/private/ProfileScreen";
import ShopScreen from "./routes/shop/ShopScreen";
import PasswordResetScreen from "./routes/public/PasswordResetScreen";
import BasketScreen from "./routes/public/BasketScreen";
import { CartProvider } from "./providers/CartContext";
import LandingPage from "./Landing/LandingPage";
import PaymentStatusScreen from "./routes/public/paymentStatus/PaymentStatusScreen";
import NewPasswordScreen from "./routes/public/NewPasswordScreen";
import OrderView from "./routes/public/OrderView";
import AuthActionHandler from "./routes/public/AuthHandler";
import ProductDetailsScreen from "./routes/public/ProductDetailsScreen";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <CartProvider>
          <Routes>
            <Route path="/paymentSuccess" element={<PaymentStatusScreen success={true} />} />
            <Route path="/paymentFailure" element={<PaymentStatusScreen success={false} />} />
            <Route path="/orders" element={<OrderView />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/signin" element={<LoginScreen />} />
            <Route path="/signup" element={<RegisterScreen />} />
            <Route path="/passwordReset" element={<PasswordResetScreen />} />
            <Route path="/createNewPassword" element={<NewPasswordScreen />} />
            <Route path="/auth-action" element={<AuthActionHandler />} />
            <Route path="/basket" element={<BasketScreen />} />
            <Route path="/shop" element={<ShopScreen />} />
            <Route path="/productDetails" element={<ProductDetailsScreen />} />
            <Route path="/paywall" element={
              <PrivateRoute>
                <PaywallScreen />
              </PrivateRoute>
            } />
            <Route path="/paymentSuccessful" element={
              <PrivateRoute>
                <DashboardScreen />
              </PrivateRoute>
            } />
            <Route path="/dashboard" element={
              <PrivateRoute>
                <DashboardScreen />
              </PrivateRoute>
            } />

            <Route path="/module" element={
              <PrivateRoute>
                <SecuredRoute>
                  <TrainingModuleScreen />
                </SecuredRoute>
              </PrivateRoute>
            } />
            <Route path="/workout" element={
              <PrivateRoute>
                <SecuredRoute>
                  <WorkoutScreen />
                </SecuredRoute>
              </PrivateRoute>} />
            <Route path="/profile" element={
              <PrivateRoute>
                <ProfileScreen />
              </PrivateRoute>
            } />
            <Route path="*" element={
              <PrivateRoute>
                <DashboardScreen />
              </PrivateRoute>
            } />
          </Routes>
        </CartProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import '../../styles/components/offers/OfferCell.css';
import { CheckCircle, XCircle, Gift } from 'react-feather'
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

export const OfferCell = ({
    originalPrice,
    currentPrice,
    planName,
    planSubtext = '',
    features = [],
    mostPopular = false,
    giftImage = null,
    giftDescription = '',
    onSelect,
}) => {
    return (
        <div className={`offer-cell ${mostPopular ? 'most-popular' : ''}`}>
            <div className="offer-header">
                {mostPopular && <span className="badge display-s">Najczęściej wybierany</span>}
                <p className="offer-original-price"></p>
                <h2 className="offer-current-price text-xl">{currentPrice} zł
                    <span className="offer-plan-duration text-md"> /miesiąc</span>
                </h2>

                <p className='offer-plan-name text-s'>{planName}</p>
                {planSubtext ? <p>{planSubtext}</p> : <div className="mb-4 text-s" />}
            </div>
            <div className='mb-5'></div>

            {mostPopular ?
                <PrimaryButton text="Wybieram" onClick={onSelect} />
                :
                <SecondaryButton text="Wybieram" onClick={onSelect} />
            }
            <div className='mb-3'></div>
            <div className='divider'></div>
            <div className="offer-features">
                {Array.isArray(features) && features.map((feature, index) => (
                    <div key={index} className="feature-item">
                        {feature.included ? (
                            <CheckCircle className="icon included" size={20} />
                        ) : (
                            <XCircle className="icon excluded" size={20} />
                        )}
                        <p className='text-md '>
                            {feature.description}
                        </p>
                    </div>
                ))}
            </div>

            {giftImage && (
                <div className="offer-gift">
                    <div className="gift-description">
                        <Gift className="gift-icon" />
                        <p className='text-md'>{giftDescription}</p>
                    </div>

                    <div className="gift-images">
                        <img src={giftImage} alt="Gift" className="gift-image" />
                    </div>
                </div>
            )}
        </div>
    );
};

export const MobileOfferCell = ({
    currentPrice,
    planName,
    planSubtext = '',
    features = [],
    mostPopular = false,
    giftImage = null,
    giftDescription = '',
    onSelect,
}) => {
    return (
        <div className='mobile-offer-cell'>
            <div className="mobile-plan-header">
                <span className="text-md semibold">{planName}</span>
            </div>
            <div className={`mobile-offer-content ${mostPopular ? 'mobile-most-popular' : ''}`}>
                <div className="mobile-plan-pricing">
                    <h2 className="display-sm semibold">{currentPrice} zł
                        <span className="offer-plan-duration text-md"> /miesiąc</span>
                    </h2>
                    {planSubtext ? <p>{planSubtext}</p> : <div className="mb-4 text-xs" />}
                </div>
                {giftImage && (
                    <div className="mobile-gift-section">
                        <div className="mobile-gift-description">
                            <Gift className="gift-icon" />
                            <p className='text-md'>{giftDescription}</p>
                        </div>
                    </div>
                )}
                <div className='mobile-btn-content'>
                    <PrimaryButton text={"Wybieram"} onClick={onSelect} />
                </div>
            </div>
            {giftImage && (
            <div className="circle-gift-content">
                <img src={giftImage} alt="Gift" className="mobile-gift-image" />
            </div>
            )}

        </div>
    )
}

// export default OfferCell;